import { BaseModel } from 'shared/models/base.model';

export class EquipmentInfo extends BaseModel {
  constructor(json) {
    super(json);

    this.containerIds = json.containerIds;
  }

  loadNumber: number;
  containerIds: number[]
  vesselName: string;
  transportationNumber: string;
  voyageId: string;
  bookingNumber: string;
  bookType: string;
  bookSeqNum: number;
}
