import { BaseModel } from 'shared/models/base.model';

export class ContainerStatus extends BaseModel {
  constructor(json: any) {
    super(json);
  }

  code: string;
  rdn: number;
  description: string;
  progressionOrder: number;
}
