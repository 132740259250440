import { BaseModel } from 'shared/models/base.model';

export class ContainerNumberUpdateResponse extends BaseModel {
  constructor(json: any) {
    super(json);
  }

  containerId: number;
  containerNumber: string;
  isAir: boolean;
}
