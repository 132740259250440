import { Inject, Service } from 'typedi';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { SearchRequest } from 'shared/models/search-request.model';
import { SearchResponse } from 'shared/models/search-response.model';
import { CacheableRepository } from 'app/repositories/cacheable.repository';
import { ShippingContainer } from 'shared/models/shipments/shipping-container.model';
import { ContainerStatus } from 'shared/models/shipments/container-status.model';
import { ShipmentSummary } from 'shared/models/shipments/shipment-summary.model';
import { EquipmentInfo } from 'shared/models/shipments/equipment-info.model';
import { ContainerNumberUpdateResponse } from 'shared/models/shipments/container-number-update-response.model';

@Service()
export class GlobalShipmentsRepository extends CacheableRepository {
  @Inject('apiConfig.globalShipmentsAPI')
  protected baseUrl;

  public getShippingContainers(loadNumber: number): Observable<ShippingContainer[]> {
    return this.get(`/Load/${loadNumber}/ShippingContainers`)
      .map(resp => resp.map(container => new ShippingContainer(container)));
  }

  public postShippingContainers(containers: ShippingContainer[]): Observable<number[]> {
    const containersJson = containers.map(c => c.toJson());
    return this.post(`/Container`, containersJson)
      .map(resp => resp.map(seqNum => seqNum));
  }

  public getContainerStatuses(loadNumber: number, bookType: string): Observable<ContainerStatus[]> {
    return this.getWithCache(`/Container/${loadNumber}/${bookType}/ContainerStatuses`)
      .map(resp => resp.map(type => new ContainerStatus(type)));
  }

  public search(query: MyShipmentsSearchRequest): Observable<SearchResponse<ShipmentSummary>> {
    const request = new SearchRequest(query, query.PageSize, query.PageNumber);

    return this.post(`/Load/Search`, request)
      .map(response => new SearchResponse(response, json => new ShipmentSummary(json)));
  }

  public getEquipmentInfo(loadNumber: number, bookSeqNum: number): Observable<EquipmentInfo> {
    return this.get(`/Load/${loadNumber}/${bookSeqNum}/EquipmentInfo`)
      .map(resp => new EquipmentInfo(resp));
  }

  public putEquipmentInfo(equipment: EquipmentInfo): Observable<boolean> {
    return this.put(`/Load/${equipment.loadNumber}/${equipment.bookSeqNum}/EquipmentInfo`, equipment)
      .map(response => true);
  }

  public updateContainerNumber(containerId: number, containerNumber: string, isAir: boolean): Observable<ContainerNumberUpdateResponse> {
    return this.put(`/Container/${containerId}/${containerNumber}`, {containerId, containerNumber})
    .map(response => new ContainerNumberUpdateResponse({ containerNumber : containerNumber, isAir: isAir}));
  }
}
