// dto for NavisphereCarrier.Shared.Wrappers.SearchResponse
export class SearchResponse<T> {
  results: T[] = [];
  pageNumber: number = 0;
  pageSize: number = 0;
  pageCount: number = 0;
  totalCount: number = 0;
  totalPages: number = 0;

  constructor(json: any, hydrator: (any) => T) {
    if (json) {
      this.results = hydrator && json.results ? json.results.map(hydrator) : json.results;

      this.pageNumber = json.pageNumber;
      this.pageSize = json.pageSize;
      this.pageCount = json.pageCount;
      this.totalCount = json.totalCount;
      this.totalPages = json.totalPages;
    }
  }
}
