import { BaseModel } from 'shared/models/base.model';

export class ShipmentLocation extends BaseModel {
  constructor(json: any) {
    super(json);
  }

  toJson() {
    return super.toJson();
  }

  city: string;
  subDiv: string;
  country: string;
  locationName: string;

}
