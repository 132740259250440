import { BaseModel } from 'shared/models/base.model';
import { ShipmentLocation } from 'shared/models/shipments/shipment-location.model';

export class ShipmentSummary extends BaseModel {
  constructor(json: any) {
    super(json);

    if (json) {
      if (json.origin) {
        this.origin = new ShipmentLocation(json.origin);
      }

      if (json.destination) {
        this.destination = new ShipmentLocation(json.destination);
      }

      this.pickUpDate = this.processDate(json.pickUpDate);
      this.deliveryDate = this.processDate(json.deliveryDate);
    }
  }

  toJson() {
    return {
      ...super.toJson(),
      origin: this.origin.toJson(),
      destination: this.destination.toJson(),
      pickUpDate: this.convertDateToDateString(this.pickUpDate),
      deliveryDate: this.convertDateToDateString(this.deliveryDate)
    };
  }

  id: number;
  origin: ShipmentLocation;
  destination: ShipmentLocation;
  pickUpDate: Date;
  deliveryDate: Date;
  loadNumber: number;
  bookingNumber: string;
  vesselName: string;
  voyageId: string;
  bookType: string;
  bookSeqNum: number;
}
