import { BaseModel } from 'shared/models/base.model';
import { TrackingLog } from 'shared/models/shipments/tracking-log.model';

export class ShippingContainer extends BaseModel {
  containerId: number;
  containerNumber: string;
  loadNumber: number;
  eventCode: number;
  containerTypeRdn: number;
  containerTypeCode: string;
  containerType: string;
  containerStatusRdn: number;
  sourceSystem: string;
  eventDateTime: Date;
  location: string;
  siteQualifierType: number;
  routeStopType: number;
  isAir: boolean;
  trackingLog: TrackingLog[];

  constructor(json) {
    super(json);

    this.eventDateTime = this.processDate(json.eventDateTime);
  }

  toJson() {
    return {
      ...super.toJson(),
      eventDateTime: this.convertDateToDateString(this.eventDateTime)
    };
  }
}
