// dto for NavisphereCarrier.Shared.Wrappers.SearchRequest
export class SearchRequest<T> {
  searchCriteria: T;
  searchOptions: {
    pageSize: number,
    pageIndex: number
  };

  constructor(criteria: T, pageSize: number, pageIndex: number) {
    this.searchCriteria = criteria;
    this.searchOptions = { pageSize, pageIndex };
  }
}
